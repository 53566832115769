<template>
    <div class="c-text" :class="comp.settings.class ? comp.settings.class : ''" v-html="comp.settings.text"></div>
</template>

<script>
  export default {
    name: 'Comp_text',
    props: {
      comp: Object
    }
  }
</script>
